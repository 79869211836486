.maincontainer {
  background-color: #f3f4f5;
}

.background {
  padding: 10px;
  background-color: white;
  width: 100%;
  margin: 20px auto;
}
.loader {
  text-align: center;
  margin: auto;
}

@media screen and (min-width: 769px) {
  .background {
    padding: 20px;
    width: 85%;
  }
}