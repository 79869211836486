.searchContainer {
  background-color: white;
  padding: 20px;
  margin-bottom: 20px;
}
input[type="search"]::-webkit-search-decoration,
input[type="search"]::-webkit-search-cancel-button,
input[type="search"]::-webkit-search-results-button,
input[type="search"]::-webkit-search-results-decoration {
  cursor: pointer;
}
.mainContainer {
  padding: 10px;
  background-color: #f3f4f5;
  padding-bottom: 50px;
}
.inputContainer {
  width: 100%;
  margin: 10px 0;
}
.tableContainer {
  margin-top: 20px;
  background-color: white;
  padding: 20px;
}
.searchHeading {
  font-size: 20px;
  font-weight: 700;
}
.inputWrapper {
  @apply w-[80%] sm:w-1/2 mr-3;
}
.inputButtonWrapper {
  display: flex;
  align-items: center;
}
.stackListContainer {
  @apply overflow-x-auto bg-white shadow sm:rounded-md;
  min-height: 400px;
}
.stackListWrapper {
  @apply divide-y divide-gray-200;
  width: auto;
}
.loader {
  width: 100%;
  background-color: white;
  display: flex;
  margin: auto;
  margin-top: 50px;
  justify-content: center;
}
.dataNotFound {
  width: 300px;
  height: 300px;
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
  margin: auto;
}
@media (min-width: 1023px) {
  .stackListWrapper {
    width: auto;
  }
  .mainContainer {
    padding: 10px 110px;
    background-color: #f3f4f5;
    padding-bottom: 50px;
  }

  .inputContainer {
    width: 30%;
    margin: 10px 0;
  }
}
