.filterContainer {
  /* box-shadow: rgba(0, 0, 0, 0.05) 0px 6px 24px 0px, rgba(0, 0, 0, 0.08) 0px 0px 0px 1px; */
  border-bottom: 1px solid rgb(210, 209, 209);
}
.mainContainer {
  padding: 10px;
  background-color: #f3f4f5;
}
.inputContainer {
  margin: 20px 55px;
}
.heading {
  background-color: white;
  padding: 10px 20px;
}
.innerContainer {
  background-color: white;
  min-height: 50vh;
}
.wrapper {
  width: 90%;
}
.loader {
  width: 60%;
  background-color: white;
  display: flex;
  margin: auto;
  margin-top: 50px;
  height: 60vh;
  justify-content: center;
}
.list {
  min-height: 50vh;
}
.stackListContainer {
  @apply overflow-x-auto bg-white shadow sm:rounded-md;
}
.stackListWrapper {
  @apply divide-y divide-gray-200;
  min-width: fit-content;
}
@media (min-width: 1023px) {
  .stackListWrapper {
    width: auto;
  }
  .mainContainer {
    padding: 10px 110px;
    margin-bottom: 20px;
    background-color: #f3f4f5;
  }
  .heading {
    padding: 10px 110px;
  }
  .inputContainer {
    margin: 20px 55px;
  }
  .heading {
    background-color: white;
    padding: 10px 20px;
  }
  .innerContainer {
    background-color: white;
    min-height: 50vh;
  }
}
