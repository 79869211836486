
.form {
    padding: 10px;
    background-color: white;
    width: 100%;
    margin: 0px auto;
  }
  .loader {
    text-align: center;
    margin: auto;
  }
  @media screen and (min-width: 769px) {
    .form {
      padding: 20px;
  
      width: 85%;
    }
  

  }