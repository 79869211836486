.filterContainer {
  border-bottom: 1px solid rgb(210, 209, 209);
}
.mainContainer {
  padding: 10px;
  background-color: #f3f4f5;
}
.inputContainer {
  margin: 20px 55px;
}
.heading {
  background-color: white;
  padding: 10px 20px;
}
.innerContainer {
  background-color: white;
  min-height: 50vh;
}

.loader {
  text-align: center;
  margin: auto;
}

.sides {
  background-color: yellow;
}

.pagination {
  width: 100%;
  margin: auto;
  background-color: white;
  margin-top: 20px;
}

.heading {
  @apply pt-5;
  padding-left: 112px;
  padding-right: 112px;
  margin: auto;
}

.headingCrump {
  @apply bg-white;
  padding-left: 10px;
  padding-right: 10px;
  margin: auto;
}

@media (min-width: 1023px) {
  .mainContainer {
    padding: 10px 110px;
    padding-top: 0;
    background-color: #f3f4f5;
  }
  .heading {
    padding: 10px 110px;
  }
}

@media only screen and (max-width: 768px) {
  .heading {
    @apply pt-5;
    padding-left: 20px;
    padding-right: 20px;
    margin: auto;
  }

  .headingCrump {
    @apply bg-white;
    padding-left: 10px;
    padding-right: 10px;
    margin: auto;
  }
}
