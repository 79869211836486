.maincontainer {
  background-color: #f3f4f5;
}

.table {
  padding-top: 10px;
  padding-bottom: 10px;
  margin: auto;
  width: 85%;
  margin-bottom: 30px;
}
.DataNotFound {
  background-color: white;
  width: 85%;
  margin: auto;
  margin-top: 20px;
}
.heading {
  width: 85%;
  margin: auto;
  margin-bottom: 10px
}

/* .headingCrump {
  @apply bg-white;
  padding-left: 10px;
  padding-right: 10px;
  margin: auto;
} */
.pagination {
  width: 85%;
  margin: auto;
  background-color: white;
}

.loader {
  text-align: center;
  margin: auto;
}

.border1px {
  border-width: 1px;
}

@media only screen and (max-width: 768px) {
  .heading {
    width: 100%;
  }

  .table {

    width: 95%;

  }

  .pagination {
    width: 100%;


  }

  /* 
  .headingCrump {
    @apply bg-white;
    padding-left: 10px;
    padding-right: 10px;
    margin: auto;
  } */
}