.maincontainer {
  background-color: white;
  width: 100%;
      margin-top: 20px;

}
.list{
  /* min-height:65vh; */
}
.stackListContainer {
  @apply overflow-x-auto bg-white shadow sm:rounded-md;
}
.stackListWrapper {
  @apply divide-y divide-gray-200;
  width: 800px;
}
@media (min-width: 1023px) {
  .stackListWrapper {
    width: auto;
  }
}
@media screen and (min-width: 480px) {
  .maincontainer {
    width: 85%;
    margin: auto;
        margin-top: 20px;

  }
}
