.overflow {
  @apply truncate overflow-scroll flex text-sm font-medium text-indigo-600;
  /* overflow: scroll; */
}
.overflowAllocation {
  @apply truncate overflow-scroll flex  font-bold text-black text-[16px];
}

.overflowAllocationAllocation {
  @apply truncate overflow-scroll flex  font-bold text-black text-[14px];
}
.img {
  width: 30%;
  margin: auto;
}
.pendingdetail {
  @apply truncate flex items-center;
  /* overflow: scroll; */
}
.pendingdetailAllocated {
  @apply truncate flex items-center;
  /* overflow: scroll; */
}
/* @media screen and (min-width: 768px) {
  .overflow {
    overflow: hidden;
  }
  .pendingdetail {
    overflow: hidden;
  }
} */
