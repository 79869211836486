.middlePoint {
  color: indigo;
  font-size: 40px;
}
.name {
  width: 500px;
  color: indigo;
  margin: 10px;
  margin-bottom: 20px;
  display: flex;
  /* justify-content: center; */
  align-items: center;
}
.mainHeading {
  font-size: 19px;
  font-weight: bold;
}
.subHeading {
  color: gray;
}
.innerContainer {
  margin: 20px;
}
.remarkContainer {
  /* display: flex; */
  margin-top: 10px;
}
.remark {
  font-weight: bold;
}
.Button {
  text-align: end;
  margin-top: 20px;
}
.remarkDescription {
  font-size: 13px;
}
.nameContainer {
  width: 100%;
  margin-top: 15px;
  margin-bottom: 10px;
  overflow-x: scroll;
  overflow-y: hidden;

}
@media (min-width: 1023px) {
  .name {
    width: 100%;
  }
  .nameContainer {
    overflow: hidden;
    text-overflow: ellipsis;
  }
  .remark {
    width: 25%;
  }
  .remarkContainer {
    display: flex;
  }
  .remarkDescription {
    margin-left: 20px;
    font-size: 13px;
    width: 80%;
  }
}
