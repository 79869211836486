.mainContainer {
  padding: 10px;
}
.innerContainer {
  background-color: white;
  padding: 20px;
  min-height: 50vh;
}
.inputContainer {
  width: 100%;
  margin: 10px 0;
}
.tableContainer {
  margin-top: 20px;
  background-color: white;
  padding: 20px;
}
.loader {
  width: 100%;
  background-color: white;
  display: flex;
  margin: auto;
  margin-top: 50px;
  justify-content: center;
}
@media (min-width: 1023px) {
  .mainContainer {
    padding: 10px 110px;
    background-color: #f3f4f5;
    margin-bottom: 100px;
  }
  .inputContainer {
    width: 30%;
    margin: 10px 0;
  }
}
