.maincontainer {
  background-color: white;
  width: 100%;
  margin-top: 20px;
}
@media screen and (min-width: 480px) {
  .maincontainer {
    width: 85%;
    margin: auto;
      margin-top: 20px;

  }
}
