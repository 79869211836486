.container {
    padding: 10px;
    background-color: #f7f5f5;
  }
  .heading{
    margin-top: 20px;
    margin-bottom: 10px;
    font-size: 20px;
    color: rgb(33, 0, 0);
    font-weight: 200;
  }