.maincontainter {
  width: 100%;
  padding: 10px;
}


.list{
  /* min-height:65vh; */
}
.stackListContainer {
  @apply overflow-x-auto bg-white shadow sm:rounded-md;
}
.stackListWrapper {
  @apply divide-y divide-gray-200;
  width: 800px;
}


.dataNotFound {
  width: 300px;
  height: 300px;
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
  margin: auto;
}
@media (min-width: 1023px) {
  .maincontainter {
    padding: 10px 110px;
    background-color: #f3f4f5;
    padding-bottom: 50px;
  }
  .stackListWrapper {
    width: auto;
  }
}
