.maincontainer {
    background-color: #f3f4f5;
  }
  
  .background {
    padding: 10px;
    background-color: white;
    width: 100%;
    margin: 20px auto;
  }
  .loader {
    text-align: center;
    margin: auto;
  }
  
  .stackListContainer {
    @apply overflow-x-auto bg-white shadow sm:rounded-md;
  }
  .stackListWrapper {
    @apply divide-y divide-gray-200;
    width: 800px;
  }
  @media (min-width: 1023px) {
    .stackListWrapper {
      width: auto;
    }
  }
  
  @media screen and (min-width: 769px) {
    .background {
      padding: 20px;
      width: 85%;
    }
  }