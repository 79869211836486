.mainContainer {
  padding: 10px;
  background-color: #f3f4f5;
}
.heading {
  background-color: white;
  padding: 10px 20px;
}
.innerContainer {
  background-color: white;
  padding: 20px;
  min-height: 50vh;
}
.loader{
  width: 60%;
  background-color: white;
  display: flex;
  margin: auto;
  margin-top: 50px;
  height: 60vh;
  justify-content: center;
}
.inputContainer {
  width: 100%;
  margin: 10px 0;
}
.inputContainerDisable {
  width: 100%;
  opacity: 0.5;
  pointer-events: none;
  margin: 10px 0;
}


  @media (min-width: 1023px){
    .mainContainer{
        padding: 10px 112px ;
        background-color: #F3F4F5;
    
      }
      .inputContainer{
        width: 30%;
        margin: 10px 0;
      }
      .inputContainerDisable {
        width: 30%;
        margin: 10px 0;
        opacity: 0.5;
        pointer-events: none;
      }
      .heading{
        padding: 10px 112px ;
          }
  }
