.heading {
  padding: 20px;
  text-align: center;
}

.maincontainer {
  background-color: #f3f4f5;

  height: 100vh;
}
.DataNotFound {
  background-color: white;
  width: 85%;
  margin: auto;
  margin-top: 20px;
}
/* .pagination {
  width: 100%;
  margin: auto;
  background-color: white;
} */
.pagination {
  background-color: white;
}

.img {
  width: 80%;
  margin: auto;
}

.loader {
  text-align: center;
  margin: auto;
}

@media screen and (min-width: 768px) {
  .img {
    width: 30%;
  }

  .heading {
    margin: auto;
    width: 85%;
    display: flex;
    /* justify-content: space-between; */
    flex-wrap: wrap;
  }

  .pagination {
    background-color: white;
    width: 85%;
    margin-left: 7%;
    position: fixed;
    bottom: 0;
  }
}
