.formHeading {
  font-size: 22px;
  font-weight: 700;
  position: relative;
}

.formHeading::after {
  content: "";
  height: 1px;
  width: 100%;
  border-radius: 10px;
  background-color: rgb(204, 203, 203);
  position: absolute;
  left: 0;
  bottom: -20%;
}

.mainForm {
  position: relative;
  width: 100%;
}

.strips {
  display: flex;
  flex-direction: column;
  margin-top: 20px;
  font-weight: 500;
}

.simpleIb {
  border: 1px solid rgb(189, 186, 186);
  width: 30%;
  border-radius: 5px;
  padding: 2px 6px;
  margin-right: 15px;
  font-weight: 400;
  outline: none;
}

.ibArea {
  display: flex;
  flex-direction: column;
  margin-top: 5px;
}

.ibArea p{
  margin-left: 0;
}

.ibVariant {
  display: flex;
  align-items: flex-start;
  margin-top: 5px;
}

.varWarn{
  display: flex;
  flex-direction: column;
  width: 30%;
  margin-right: 15px;
}

.varWarn input{
  width: 100%;
}

.varWarn p{
  width: 100%;
}

.ibModel {
  display: flex;
  flex-direction: column;
  margin-top: 5px;
}

.imgstt{
  display: flex;
}

.imgstt label{
  margin-right: 15px;
}

.addBtn {
  font-size: 22px;
  cursor: pointer;
  color: #374151;
  border: 1px solid #374151;
  border-radius: 50%;
}

.addBtn:hover {
  color: white;
  background-color: #374151;
}

.imgIb {
  width: 0;
  margin-right: 15px;
  outline: none;
}

.imgStrip {
  display: flex;
  flex-direction: column;
  margin-top: 30px;
  font-weight: 500;
}

.ibImgArea {
  display: flex;
  align-items: center;
}

.ibImgArea a{
  font-size: 14px;
}

.addBtn {
  font-size: 22px;
  cursor: pointer;
  color: #374151;
  border: 1px solid #374151;
  border-radius: 50%;
}

.ibImgArea span:hover {
  color: white;
  background-color: #374151;
}

.btnArea {
  display: flex;
}

.variantContainer {
  position: absolute;
  right: 0;
  top: 0;
  width: 300px;
  height: 180px;
  padding: 5px 0;
  overflow-y: auto;
}

.colorContainer {
  position: absolute;
  right: 0;
  top: 230px;
  width: 300px;
  height: 180px;
  padding: 5px 0;
  overflow-y: auto;
}

.colorContainer::before {
  content: "";
  position: absolute;
  top: -12px;
  border-radius: 15px;
  height: 1px;
  width: 300px;
  background-color: rgb(187, 182, 182);
}

.variantInner {
  height: 100%;
  margin-top: 5px;
}

.variantInner span {
  font-size: 14px;
}

.variantInner span span {
  font-size: 12px;
}

.colorInner {
  /* border: 1px solid rgb(211, 210, 210); */
  height: 100%;
  margin-top: 5px;
}

.colorInner span {
  font-size: 14px;
}

.colorInner span span {
  font-size: 12px;
}

.warningReq{
  color: rgb(236, 101, 101);
  font-size: 13px;
  margin-top: 2px;
}

@media only screen and (max-width: 1000px) {
  .simpleIb {
    width: 88%;
  }

  .varWarn{
    width: 100%;
  }

  .variantContainer {
    position: static;
    width: 100%;
    height: 150px;
    margin-top: 20px;
    margin-bottom: 20px;
  }

  .colorContainer {
    position: static;
    width: 100%;
    height: 150px;
    margin-top: 20px;
    margin-bottom: 20px;
  }

  .colorContainer::before {
    display: none;
  }
}
