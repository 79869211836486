.maincontainer {
  background-color: #f3f4f5;
}

.heading {
  width: 85%;
  margin: auto;
  margin-bottom: 10px
}



.formSpace {
  padding: 20px;
  background-color: white;
}

.tableCover {
  margin-top: 20px;
  padding: 10px;
  background-color: #f3f4f5;
}



@media only screen and (max-width: 768px) {
  .heading {
    width: 100%;
  }



}