.filterContainer {
  /* box-shadow: rgba(0, 0, 0, 0.05) 0px 6px 24px 0px, rgba(0, 0, 0, 0.08) 0px 0px 0px 1px; */
  border-bottom: 1px solid rgb(210, 209, 209);
}
.mainContainer {
  padding: 10px;
  margin-bottom: 30px;
  background-color: #f3f4f5;
}
.inputContainer {
  margin: 20px 55px;
}
.loader {
  width: 60%;
  background-color: white;
  display: flex;
  margin: auto;
  margin-top: 50px;
  height: 60vh;
  justify-content: center;
}
.heading {
  background-color: white;
  padding: 10px 20px;
}

@media (min-width: 1023px) {
  .mainContainer {
    padding: 10px 112px;
    background-color: #f3f4f5;
  }
  .heading {
    padding: 10px 112px;
  }
}
