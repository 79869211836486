.maincontainer {
  background-color: #f3f4f5;
}

.table {
  padding-top: 10px;
  padding-bottom: 10px;
  margin: auto;
  width: 85%;
  margin-bottom: 30px;
}
.DataNotFound {
  background-color: white;
  width: 85%;
  margin: auto;
  margin-top: 20px;
}
.heading {
  width: 85%;
  margin: auto;
  margin-bottom: 10px;
}

.pagination {
  width: 85%;
  margin: auto;
  background-color: white;
}
.img {
  width: 25%;
  margin: auto;
}
.loader {
  text-align: center;
  margin: auto;
}
@media only screen and (max-width: 768px) {
  .img {
    width: 80%;
  }
  .heading {
    width: 100%;
  }

  .table {
    width: 95%;
  }

  .pagination {
    width: 100%;
  }
}
