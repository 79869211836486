.mainContainer {
  padding: 10px;
  padding-bottom: 5px;
}
.searchContainer {
  background-color: white;
  padding: 0 20px;
  padding-bottom: 10px;
}
.searchContainerDisabled {
  background-color: white;
  padding: 0 20px;
  padding-bottom: 10px;
  opacity: 0.5;
  pointer-events: none;
}
.deliveredButton {
  opacity: 0.5;
  pointer-events: none;
  visibility: hidden;
}
.inputContainer {
  width: 100%;
  margin: 10px 0;
}
.tableContainer {
  margin-top: 20px;
  background-color: white;
  padding: 20px;
}
.searchHeading {
  font-size: 20px;
  font-weight: 700;
}
.inputWrapper {
  width: 100%;
}
.inputWrapperDisable {
  width: 50%;
  margin-right: 10px;
  opacity: 0.5;
  pointer-events: none;
  cursor: not-allowed;
}
.inputButtonWrapper {
  display: flex;
  align-items: center;
}
.inputRow {
  @apply grid grid-cols-1 gap-4 sm:grid-cols-3 md:grid-cols-4 lg:grid-cols-5 xl:grid-cols-6;
  margin-top: 10px;
}

.inputRowDisable {
  @apply grid grid-cols-1 gap-4 sm:grid-cols-3 md:grid-cols-4 lg:grid-cols-5 xl:grid-cols-6;
  margin-top: 10px;
  opacity: 0.5;
  pointer-events: none;
}
.preferenceRow {
  @apply grid grid-cols-1 gap-4 sm:grid-cols-1 md:grid-cols-1 lg:grid-cols-3;
  margin-top: 5px;
}
.preferenceWrapper {
  @apply grid sm:grid-cols-2 gap-4;
}
.rigthSide {
  @apply grid grid-cols-1 sm:grid-cols-3 gap-4 mt-4;
}

.loader {
  width: 60%;
  background-color: white;
  display: flex;
  margin: auto;
  margin-top: 50px;
  height: 60vh;
  justify-content: center;
}
.inputDisable {
  opacity: 0.5;
  pointer-events: none;
}
.headingFormContainerflex {
  display: block;
  margin-top: 10px;
  justify-content: space-between;
}
.headingFormContainer {
  margin-bottom: 10px;
}
.leftSide {
  width: 100%;
}

.inputWrapperDisable {
  width: 100%;
  pointer-events: none;
  margin-bottom: 15px;
  opacity: 0.5;
  cursor: not-allowed;
}
.radiobuttonWrapper {
  display: flex;
}

.buttonWrapper {
  display: flex;
  padding-top: 10px;
  background-color: white;
  align-items: center;
  justify-content: center;
}
.cancelButton {
  margin-left: 20px;
}
.cancelButtonDisable {
  margin-left: 20px;
  pointer-events: none;
  visibility: hidden;
  opacity: 0.5;
}
.selectInput {
  margin: 20px 0;
}
.modalInputContainer {
  margin: 20px 0;
}
.submitButtonWrapper {
  display: flex;
  justify-content: end;
  margin-top: 25px;
}
.submitButton {
  margin-left: 20px;
}
.dropDownWrapper {
  margin-top: 0;
  margin-bottom: 20px;
}
.dropDownWrapperDisable {
  margin-top: 0;
  margin-bottom: 20px;
  opacity: 0.5;
  pointer-events: none;
}
.preference {
  margin-top: 5px;
  font-size: 15px;
  font-weight: 500;
}
.error {
  color: red;
  font-size: 14px;
  font-style: italic;
}
.inputFile {
  cursor: pointer;
}
.inputFile::file-selector-button {
  display: none;
}
@media (min-width: 1023px) {
  .headingFormContainerflex {
    display: flex;
  }
  .HeadingWrapper {
    display: flex;
  }
  .innerWrapper {
    width: 50%;
  }
  .modalInputContainer {
    margin: 20px 55px;
  }
  .leftSide {
    width: 31%;
  }
  .buttonWrapper {
    display: flex;
    padding-right: 112px;
    width: 50%;
    margin-bottom: 16px;
    background-color: white;
    align-items: center;
    justify-content: flex-end;
  }

  .mainContainer {
    padding: 0 110px;
    background-color: #f3f4f5;
  }
  .inputContainer {
    width: 30%;
    margin: 10px 0;
  }
}
