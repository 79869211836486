.maincontainer {
  background-color: #f3f4f5;
  height: 100vh;
}

.form {
  padding: 10px;
  background-color: white;
  width: 100%;
  margin: 0px auto;
}
.loader {
  text-align: center;
  margin: auto;
}
@media screen and (min-width: 769px) {
  .form {
    padding: 20px;

    width: 85%;
  }

  .table {
    background-color: #f3f4f5;
    padding-top: 10px;
    padding-bottom: 10px;
  }
}
