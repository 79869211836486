.maincontainer {
  background-color: #f3f4f5;
}

.form {
  padding: 10px;
  background-color: white;
  width: 100%;
  margin: 20px auto;
}

.feildswidth {
  padding: 10px;
}

.buttons {
  padding: 20px;
  display: flex;
  justify-content: center;
}
.loader {
  text-align: center;
  margin: auto;
}

@media screen and (min-width: 769px) {
  .form {
    padding: 20px;

    width: 85%;
  }

  .flex {
    margin: 5px;
    flex-wrap: wrap;
    flex-direction: row;
    display: flex;
  }

  .feildswidth {
    width: 32%;
  }

  .buttons {
    justify-content: flex-end;
  }
  .table {
    background-color: #f3f4f5;
    padding-top: 10px;
    padding-bottom: 10px;
  }
}
