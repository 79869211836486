.heading {
  background-color: #f3f4f5;
  padding: 10px 20px;
}
.loader {
  width: 60%;
  background-color: white;
  display: flex;
  margin: auto;
  margin-top: 50px;
  height: 60vh;
  justify-content: center;
}
.MainContainer {
  /* min-height: 70vh; */
}
@media (min-width: 1023px) {
  .heading {
    padding: 10px 100px;
  }
}
